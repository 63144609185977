module.exports = {
  ENUM_ONAY_ADIM_PATH: {
    RESERVED: "/hizmet-saglayici/basvuru/form",

    INITIAL_SUBMITTED_TO_AGENT: "/hizmet-saglayici/basvuru/sonuc",
    INITIAL_AGENT_REJECTED: "/hizmet-saglayici/basvuru/bilgi-girisi",
    INITIAL_DOCUMENTS_EDITED: "/hizmet-saglayici/basvuru/sonuc",
    INITIAL_AGENT_CONFIRMED: "/hizmet-saglayici/basvuru/bilgi-girisi",

    FINAL_SUBMITTED_TO_AGENT: "/hizmet-saglayici/basvuru/sonuc",
    FINAL_AGENT_REJECTED: "/hizmet-saglayici/basvuru/bilgi-girisi",
    FINAL_DOCUMENTS_EDITED: "/hizmet-saglayici/basvuru/sonuc",
    FINAL_AGENT_CONFIRMED: "/hizmet-saglayici/basvuru/sonuc"

    // INITIAL_SUBMITTED_TO_AGENT: "/hizmet-saglayici/basvuru/sonuc-on-basvuru",
    // INITIAL_AGENT_REJECTED: "/hizmet-saglayici/basvuru/sonuc-detay-on-basvuru",
    // INITIAL_DOCUMENTS_EDITED: "/hizmet-saglayici/basvuru/sonuc-on-basvuru",
    // INITIAL_AGENT_CONFIRMED: "/hizmet-saglayici/basvuru/bilgi-girisi",

    // FINAL_SUBMITTED_TO_AGENT: "/hizmet-saglayici/basvuru/sonuc",
    // FINAL_AGENT_REJECTED: "/hizmet-saglayici/basvuru/bilgi-girisi",
    // FINAL_DOCUMENTS_EDITED: "/hizmet-saglayici/basvuru/sonuc",
    // FINAL_AGENT_CONFIRMED: "/hizmet-saglayici/basvuru/sonuc"

    // RECORDED: "/hizmet-saglayici/basvuru/dogrulama",
    // VERIFIED: "/hizmet-saglayici/basvuru/bilgi-girisi",
    // BRANDING_DONE: "/hizmet-saglayici/basvuru/bilgi-girisi",
    // DOCUMENTS_UPLOADED: "/hizmet-saglayici/basvuru/bilgi-girisi",
    // SUBMITTED_TO_AGENT: "/hizmet-saglayici/basvuru/bilgi-girisi",
    // AGENT_REJECTED: "/hizmet-saglayici/basvuru/bilgi-girisi",
    // AGENT_CONFIRMED: "/hizmet-saglayici/basvuru/bilgi-girisi"

    // VERIFIED: "/hizmet-saglayici/basvuru/ileti-yonetimi",
    // BRANDING_DONE: "/hizmet-saglayici/basvuru/belge-yukleme",
    // DOCUMENTS_UPLOADED: "/hizmet-saglayici/basvuru/onaylama",
    // SUBMITTED_TO_AGENT: "/hizmet-saglayici/basvuru/sonuc",
    // AGENT_REJECTED: "/hizmet-saglayici/basvuru/sonuc",
    // AGENT_CONFIRMED: "/hizmet-saglayici/basvuru/sonuc"
  },

  ENUM_BELGE_TURU: {
    IMZA_SIRKULERI: {
      order: 1,
      str: "İmza sirküleri",
      api_path: "file/imza-sirkuleri"
    },
    TICARET_SICIL_GAZETESI: {
      order: 2,
      str: "Ticaret sicil gazetesi",
      api_path: "file/tic-sic-gazetesi"
    },
    HIZMET_SOZLESMESI: {
      order: 3,
      str: "Kullanım taahhütnamesi",
      api_path: "file/hizmet-sozlesmesi"
    },
    MARKA_TESCIL_BELGESI: {
      order: 4,
      str: "Marka tescil belgesi",
      api_path: "file/marka-tescil-belgesi"
    }
  },

  ENUM_BELGE_UZANTISI_SVG: {
    "image/jpeg": "file_jpg.svg",
    "image/png": "file_png.svg",
    "application/pdf": "file_pdf.svg"
  },

  ENUM_BELGE_STATUS_INFO: {
    NEW: {
      icon: require("@/assets/img/ico_pending.svg"),
      label: "Değiştir",
      type: "file",
      box_class: "new"
    },
    PENDING: {
      icon: require("@/assets/img/ico_pending.svg"),
      label: "Onay bekliyor",
      type: "text",
      box_class: "pending"
    },
    REJECTED: {
      icon: require("@/assets/img/ico_x.svg"),
      label: "Değiştir",
      type: "file",
      box_class: "rejected"
    },
    CONFIRMED: {
      icon: require("@/assets/img/ico_tick.svg"),
      label: "Onaylandı",
      type: "text",
      box_class: "approved"
    }
  },

  ENUM_BANKA_UI_INFO: {
    1: { logo: require("@/assets/img/bank_logo/is_bankasi_logo.svg") },
    2: { logo: require("@/assets/img/bank_logo/akbank_logo.svg") },
    3: { logo: require("@/assets/img/bank_logo/qnb_finansbank_logo.svg") },
    4: { logo: require("@/assets/img/bank_logo/garanti_bbva_logo.svg") },
    5: { logo: require("@/assets/img/bank_logo/halkbank_logo.svg") },
    6: { logo: require("@/assets/img/bank_logo/yapikredi_logo.svg") },
    7: { logo: require("@/assets/img/bank_logo/ziraat_bankasi_logo.svg") }
  }
};

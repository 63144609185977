<template>
  <div class="page">
    <div class="formcontainer">
      <div class="formbox">
        <!-- <h2>
          Hizmet Sağlayıcılar için,<br />Başvuruya Devam Etme / Başvuru
          Sorgulama Ekranı
        </h2> -->

        <h2>Başvuru Sorgulama Formu</h2>

        <div class="formhead mb">
          <div>
            <p>
              İşlem yapabilmek için başvuru aşamasında belirttiğiniz
              <b>e-posta adresinize</b> ve <b>başvuru numarasına</b> ihtiyaç
              duyulmaktadır. <br />
              Başvuru numarasına ulaşılamadığı hallerde işlemlere devam
              edilemediğinden lütfen bizimle
              <router-link to="/iletisim" class="blue">iletişim</router-link>e
              geçiniz.
            </p>
          </div>
        </div>

        <div class="formpart center mt2">
          <div class="formleft">
            <form action="#" onSubmit="return false;">
              <div class="formline">
                <div :class="{ notvalid: problem.appNo }" class="forminput">
                  <!-- <div class="withbtn"> -->
                  <label>Başvuru Numarası:</label>
                  <!-- </div> -->
                  <input
                    ref="inputAppNo"
                    type="text"
                    name="basvuruno"
                    v-model="appNo"
                    @input="problem.appNo = ''"
                    @keyup.enter="$refs.inputEmail.focus()"
                  />
                  <div class="hint">{{ problem.appNo }}</div>
                </div>

                <div :class="{ notvalid: problem.email }" class="forminput">
                  <label>E-posta:</label>
                  <input
                    type="text"
                    name="email"
                    v-model="email"
                    @input="problem.email = ''"
                    @keyup.enter="$refs.capcha.focus()"
                  />
                  <div class="hint">{{ problem.email }}</div>
                </div>
                <capcha
                  ref="capcha"
                  v-model="captcha"
                  section="APP-FIND"
                  :message="problem.captchaCode"
                  @enter="
                    captcha.captchaCode.length == 6 ? DEVAM_ET_CLICK() : null
                  "
                  @input="problem.captchaCode = ''"
                ></capcha>
              </div>

              <div
                class="btn"
                :class="{ dsbl: captcha.captchaCode.length !== 6 }"
                @click="
                  captcha.captchaCode.length == 6 ? DEVAM_ET_CLICK() : null
                "
              >
                <a>Devam Et</a>
              </div>
            </form>
          </div>

          <!-- <div class="formright">
            <div class="formline caution">
              <h4>Dikkat Edilmesi Gereken Noktalar</h4>
              <ul>
                <li>
                  <span
                    >Doğrulama sonrasında yarım kalan başvurunuza devam edebilir
                    ya da aldığınız bildirime ilişkin işlemlerinizi
                    yürütebilirsiniz.</span
                  >
                </li>
                <li>
                  <span
                    >Belgelerinizde değişiklik yapmak üzere başvurunuzu
                    sorguluyorsanız, bildirilen belgelerin önceden hazır
                    edilmesi kolaylık sağlayacaktır.</span
                  >
                </li>
              </ul>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { ENUM_ONAY_ADIM_PATH } from "../../../misc/enums";

export default {
  name: "HSBasvuruGiris",

  data() {
    return {
      appNo: "",
      email: "",

      captcha: {
        captchaId: "",
        captchaCode: ""
      },

      problem: {
        captchaCode: "",
        email: "",
        appNo: ""
      }
    };
  },

  mounted() {
    this.appNo = this.$router.currentRoute.query.bn || "";
    this.email = this.$router.currentRoute.query.email || "";
    this.$store.commit("app/HIDE_DUYURU");

    if (this.appNo || this.email) return;

    if (this.$env.MOCK) {
      this.appNo = "MH4XE7ZS"; //DEVAM
      // this.appNo = "MT1ZS658";  //RED
      this.email = "caner@yetkilicicekcilik.com.tr";
    }
  },

  computed: {
    ...mapState("applicant", ["application"])
  },

  methods: {
    ...mapActions("applicant", ["find_basvuru"]),

    ...mapMutations("applicant", ["SET_APPLICATION", "MERGE_TO_APPLICATION"]),

    DEVAM_ET_CLICK() {
      this.clearValidationErrorDisplays();

      this.find_basvuru({
        appNo: this.appNo.trim(),
        email: this.email.trim(),
        ...this.captcha
      })
        .then(res => {
          // _paq.push(["trackEvent", "Devam", "Devam_Giris", "Success"]);
          this.SET_APPLICATION(res.payload);
          this.$router.push(ENUM_ONAY_ADIM_PATH[res.payload.step]);
        })
        .catch(cerror => {
          // _paq.push(["trackEvent", "Devam", "Devam_Giris", "Error"]);
          this.HandleValidationError(cerror);
        });
    }
  }
};
</script>

<style>
footer .footercontainer {
  padding-top: 10vh;
}
</style>
